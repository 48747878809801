export const positivePrompts = [
  { text: 'What are three things you appreciate about your intelligence?', index: 1, topic: 'positive thinking' },
  { text: 'Describe a positive experience you had recently.', index: 2, topic: 'positive thinking' },
  { text: 'What is something you are looking forward to?', index: 3, topic: 'positive thinking' },
  { text: 'Write about a time you overcame a challenge.', index: 4, topic: 'positive thinking' },
  { text: 'What is a personal strength you are proud of?', index: 5, topic: 'positive thinking' },
  { text: 'Who is someone who has positively impacted your life?', index: 6, topic: 'positive thinking' },
  { text: 'What is a recent accomplishment you are proud of?', index: 7, topic: 'positive thinking' },
  { text: 'Describe a place that makes you feel happy.', index: 8, topic: 'positive thinking' },
  { text: 'What is a hobby or activity that brings you joy?', index: 9, topic: 'positive thinking' },
  { text: 'Write about a time you helped someone.', index: 10, topic: 'positive thinking' },
  { text: 'What is a positive trait you admire in yourself?', index: 11, topic: 'positive thinking' },
  { text: 'What are three things you love about yourself?', index: 12, topic: 'positive thinking' },
  { text: 'Describe a happy memory from your childhood.', index: 13, topic: 'positive thinking' },
  { text: 'What is something you are proud of learning?', index: 14, topic: 'positive thinking' },
  { text: 'What is a recent act of kindness you performed or witnessed?', index: 15, topic: 'positive thinking' },
  { text: 'What is a goal you have achieved recently?', index: 16, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly at peace.', index: 17, topic: 'positive thinking' },
  { text: 'What is a positive change you have made in your life?', index: 18, topic: 'positive thinking' },
  { text: 'Describe a time when you felt proud of yourself.', index: 19, topic: 'positive thinking' },
  { text: 'What is something you love about your life?', index: 20, topic: 'positive thinking' },
  { text: 'What is a positive habit you have developed?', index: 21, topic: 'positive thinking' },
  { text: 'Write about a time you felt inspired.', index: 22, topic: 'positive thinking' },
  { text: 'What is a kind act you have done recently?', index: 23, topic: 'positive thinking' },
  { text: 'What is something you are excited about?', index: 24, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly happy.', index: 25, topic: 'positive thinking' },
  { text: 'What is a positive affirmation you can say to yourself?', index: 26, topic: 'positive thinking' },
  { text: 'What is something you appreciate about your body?', index: 27, topic: 'positive thinking' },
  { text: 'Write about a time you felt loved.', index: 28, topic: 'positive thinking' },
  { text: 'What is a positive lesson you have learned?', index: 29, topic: 'positive thinking' },
  { text: 'What is something you are proud of creating?', index: 30, topic: 'positive thinking' },
  { text: 'Describe a time when you felt confident.', index: 31, topic: 'positive thinking' },
  { text: 'What is a positive thought you had today?', index: 32, topic: 'positive thinking' },
  { text: 'Write about a person who always makes you smile.', index: 33, topic: 'positive thinking' },
  { text: 'Write about a time you felt supported.', index: 34, topic: 'positive thinking' },
  { text: 'What is a positive goal you have set for yourself?', index: 35, topic: 'positive thinking' },
  { text: 'What is something you love about your personality?', index: 36, topic: 'positive thinking' },
  { text: 'Describe a time when you felt successful.', index: 37, topic: 'positive thinking' },
  { text: 'What is a positive memory you cherish?', index: 38, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving?', index: 39, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly alive.', index: 40, topic: 'positive thinking' },
  { text: 'Describe something special or exceptional about yourself.', index: 41, topic: 'positive thinking' },
  { text: 'What are some of your favorite hobbies and why?', index: 42, topic: 'positive thinking' },
  { text: 'Describe a time when you felt at peace with yourself.', index: 43, topic: 'positive thinking' },
  { text: 'What is a positive impact you have made on someone?', index: 44, topic: 'positive thinking' },
  { text: 'What is something you are proud of doing for yourself?', index: 45, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly content.', index: 46, topic: 'positive thinking' },
  { text: 'What is a positive way you handle stress?', index: 47, topic: 'positive thinking' },
  { text: 'Describe a recent moment that made you laugh out loud.', index: 48, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly understood.', index: 49, topic: 'positive thinking' },
  { text: 'What is a positive change you have made for your health?', index: 50, topic: 'positive thinking' },
  { text: 'What is something you are proud of accomplishing at work?', index: 51, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly relaxed.', index: 52, topic: 'positive thinking' },
  { text: 'What is a positive way you show love to others?', index: 53, topic: 'positive thinking' },
  { text: 'What are some of your favorite inspirational quotes?', index: 54, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly appreciated.', index: 55, topic: 'positive thinking' },
  { text: 'What is a positive way you handle challenges?', index: 56, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal life?', index: 57, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly motivated.', index: 58, topic: 'positive thinking' },
  { text: 'What is a positive way you take care of yourself?', index: 59, topic: 'positive thinking' },
  { text: 'What are some things you are excited about in the future?', index: 60, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly happy with your achievements.', index: 61, topic: 'positive thinking' },
  { text: 'What is a positive way you express your creativity?', index: 62, topic: 'positive thinking' },
  { text: 'What is something you are proud of learning recently?', index: 63, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly connected to someone.', index: 64, topic: 'positive thinking' },
  { text: 'What is a positive way you manage your time?', index: 65, topic: 'positive thinking' },
  { text: 'Describe a beautiful place you have visited.', index: 66, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your efforts.', index: 67, topic: 'positive thinking' },
  { text: 'What is a positive way you handle disappointment?', index: 68, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your hobbies?', index: 69, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly energized.', index: 70, topic: 'positive thinking' },
  { text: 'What is a positive way you show kindness to yourself?', index: 71, topic: 'positive thinking' },
  { text: 'Write about a time when you helped someone and how it made you feel.', index: 72, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly fulfilled.', index: 73, topic: 'positive thinking' },
  { text: 'What is a positive way you handle criticism?', index: 74, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your relationships?', index: 75, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly optimistic.', index: 76, topic: 'positive thinking' },
  { text: 'What is a positive way you celebrate your successes?', index: 77, topic: 'positive thinking' },
  { text: 'Describe a skill or talent you have that you are proud of.', index: 78, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly at ease.', index: 79, topic: 'positive thinking' },
  { text: 'What is a positive way you handle change?', index: 80, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your community?', index: 81, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly inspired by someone.', index: 82, topic: 'positive thinking' },
  { text: 'What is a positive way you practice self-care?', index: 83, topic: 'positive thinking' },
  { text: 'What are some ways you practice self-care?', index: 84, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly confident in your abilities.', index: 85, topic: 'positive thinking' },
  { text: 'What is a positive way you handle setbacks?', index: 86, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal growth?', index: 87, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly joyful.', index: 88, topic: 'positive thinking' },
  { text: 'What is a positive way you show gratitude?', index: 89, topic: 'positive thinking' },
  { text: 'What is a recent compliment you received and how did it make you feel?', index: 90, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly at home.', index: 91, topic: 'positive thinking' },
  { text: 'What is a positive way you handle uncertainty?', index: 92, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your spiritual life?', index: 93, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly peaceful.', index: 94, topic: 'positive thinking' },
  { text: 'What is a positive way you nurture your relationships?', index: 95, topic: 'positive thinking' },
  { text: 'What are some activities that bring you joy?', index: 96, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly balanced.', index: 97, topic: 'positive thinking' },
  { text: 'What is a positive way you handle difficult emotions?', index: 98, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your mental health?', index: 99, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly hopeful.', index: 100, topic: 'positive thinking' },
  { text: 'What is a positive way you handle conflicts?', index: 101, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly empowered.', index: 102, topic: 'positive thinking' },
  { text: 'What is a positive way you manage your energy?', index: 103, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your education?', index: 104, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly appreciated by someone.', index: 105, topic: 'positive thinking' },
  { text: 'What is a positive way you handle misunderstandings?', index: 106, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly content with your life.', index: 107, topic: 'positive thinking' },
  { text: 'What is a positive way you support others?', index: 108, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your career?', index: 109, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly connected to nature.', index: 110, topic: 'positive thinking' },
  { text: 'What is a positive way you handle pressure?', index: 111, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly satisfied with your work.', index: 112, topic: 'positive thinking' },
  { text: 'What is a positive way you express your emotions?', index: 113, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your fitness?', index: 114, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your life.', index: 115, topic: 'positive thinking' },
  { text: 'What is a positive way you handle boredom?', index: 116, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your decisions.', index: 117, topic: 'positive thinking' },
  { text: 'What is a positive way you handle mistakes?', index: 118, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your diet?', index: 119, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in harmony with yourself.', index: 120, topic: 'positive thinking' },
  { text: 'What is a positive way you handle criticism?', index: 121, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your resilience.', index: 122, topic: 'positive thinking' },
  { text: 'What is a positive way you handle loneliness?', index: 123, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your creativity?', index: 124, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in sync with your goals.', index: 125, topic: 'positive thinking' },
  { text: 'What is a positive way you handle fear?', index: 126, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your patience.', index: 127, topic: 'positive thinking' },
  { text: 'What is a positive way you handle anger?', index: 128, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-discipline?', index: 129, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in tune with your emotions.', index: 130, topic: 'positive thinking' },
  { text: 'What is a positive way you handle sadness?', index: 131, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your adaptability.', index: 132, topic: 'positive thinking' },
  { text: 'What is a positive way you handle frustration?', index: 133, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your mindfulness?', index: 134, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your reactions.', index: 135, topic: 'positive thinking' },
  { text: 'What is a positive way you handle anxiety?', index: 136, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your focus.', index: 137, topic: 'positive thinking' },
  { text: 'What is a positive way you handle stress at work?', index: 138, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your relaxation?', index: 139, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your thoughts.', index: 140, topic: 'positive thinking' },
  { text: 'What is a positive way you handle peer pressure?', index: 141, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your independence.', index: 142, topic: 'positive thinking' },
  { text: 'What is a positive way you handle social situations?', index: 143, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your social life?', index: 144, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your environment.', index: 145, topic: 'positive thinking' },
  { text: 'What is a positive way you handle financial stress?', index: 146, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your financial decisions.', index: 147, topic: 'positive thinking' },
  { text: 'What is a positive way you handle unexpected changes?', index: 148, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your time management?', index: 149, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your schedule.', index: 150, topic: 'positive thinking' },
  { text: 'What is a positive way you handle procrastination?', index: 151, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your achievements.', index: 152, topic: 'positive thinking' },
  { text: 'What is a positive way you handle self-doubt?', index: 153, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-improvement?', index: 154, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your emotions.', index: 155, topic: 'positive thinking' },
  { text: 'What is a positive way you handle negative thoughts?', index: 156, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-awareness.', index: 157, topic: 'positive thinking' },
  { text: 'What is a positive way you handle criticism from others?', index: 158, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-care routine?', index: 159, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your actions.', index: 160, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed?', index: 161, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your decision-making.', index: 162, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling unmotivated?', index: 163, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal development?', index: 164, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your habits.', index: 165, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling stuck?', index: 166, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your perseverance.', index: 167, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling discouraged?', index: 168, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your daily routine?', index: 169, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your mindset.', index: 170, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling uninspired?', index: 171, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your creativity.', index: 172, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling anxious about the future?', index: 173, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your mental resilience?', index: 174, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your reactions.', index: 175, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by tasks?', index: 176, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your time management.', index: 177, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling unproductive?', index: 178, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your work-life balance?', index: 179, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your career.', index: 180, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling disconnected?', index: 181, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your social skills.', index: 182, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling isolated?', index: 183, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your friendships?', index: 184, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your relationships.', index: 185, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling misunderstood?', index: 186, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your empathy.', index: 187, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling judged?', index: 188, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your communication skills?', index: 189, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your interactions.', index: 190, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by emotions?', index: 191, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your emotional intelligence.', index: 192, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling out of control?', index: 193, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your emotional regulation?', index: 194, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your stress levels.', index: 195, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by responsibilities?', index: 196, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your ability to stay calm.', index: 197, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by change?', index: 198, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your adaptability?', index: 199, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your adaptability.', index: 200, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by expectations?', index: 201, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-control.', index: 202, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling unappreciated?', index: 203, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-awareness?', index: 204, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-esteem.', index: 205, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling undervalued?', index: 206, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-reliance.', index: 207, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by negativity?', index: 208, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-confidence?', index: 209, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-worth.', index: 210, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by emotions?', index: 211, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-discipline.', index: 212, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by tasks?', index: 213, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-care?', index: 214, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-talk.', index: 215, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by responsibilities?', index: 216, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-motivation.', index: 217, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by change?', index: 218, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-improvement?', index: 219, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-expression.', index: 220, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by social situations?', index: 221, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-acceptance.', index: 222, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by work?', index: 223, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-reflection?', index: 224, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-perception.', index: 225, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by deadlines?', index: 226, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-awareness.', index: 227, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by commitments?', index: 228, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-discipline?', index: 229, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-care routine.', index: 230, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by expectations?', index: 231, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-reliance.', index: 232, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by negativity?', index: 233, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-confidence?', index: 234, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-worth.', index: 235, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by emotions?', index: 236, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-discipline.', index: 237, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by tasks?', index: 238, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-care?', index: 239, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-talk.', index: 240, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by responsibilities?', index: 241, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-motivation.', index: 242, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by change?', index: 243, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-improvement?', index: 244, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-expression.', index: 245, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by social situations?', index: 246, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your self-acceptance.', index: 247, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by work?', index: 248, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your self-reflection?', index: 249, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your self-perception.', index: 250, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your goals?', index: 251, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your achievements in personal development.', index: 252, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your to-do list?', index: 253, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your daily habits?', index: 254, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your daily routine.', index: 255, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your responsibilities?', index: 256, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your ability to stay organized.', index: 257, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your commitments?', index: 258, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal goals?', index: 259, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your personal growth.', index: 260, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your emotions?', index: 261, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your emotional resilience.', index: 262, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your thoughts?', index: 263, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your mental health journey?', index: 264, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your mental state.', index: 265, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your workload?', index: 266, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your work ethic.', index: 267, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your schedule?', index: 268, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your time management skills?', index: 269, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your productivity.', index: 270, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your projects?', index: 271, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your project management skills.', index: 272, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your ambitions?', index: 273, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your career development?', index: 274, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your career path.', index: 275, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your aspirations?', index: 276, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your long-term goals.', index: 277, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your dreams?', index: 278, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your vision for the future?', index: 279, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your future plans.', index: 280, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your expectations?', index: 281, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your ability to manage expectations.', index: 282, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your desires?', index: 283, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your pursuit of happiness?', index: 284, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your happiness.', index: 285, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your passions?', index: 286, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your dedication to your passions.', index: 287, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your interests?', index: 288, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your hobbies?', index: 289, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your leisure time.', index: 290, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your creative projects?', index: 291, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your creative achievements.', index: 292, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your artistic pursuits?', index: 293, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your artistic endeavors?', index: 294, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your creative process.', index: 295, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your personal projects?', index: 296, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your personal achievements.', index: 297, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your personal goals?', index: 298, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal life?', index: 299, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your personal journey.', index: 300, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your responsibilities?', index: 301, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your ability to stay focused.', index: 302, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your commitments?', index: 303, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal growth?', index: 304, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your personal development.', index: 305, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your emotions?', index: 306, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your emotional strength.', index: 307, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your thoughts?', index: 308, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your mental health journey?', index: 309, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your mental state.', index: 310, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your workload?', index: 311, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your work ethic.', index: 312, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your schedule?', index: 313, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your time management skills?', index: 314, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your productivity.', index: 315, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your projects?', index: 316, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your project management skills.', index: 317, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your ambitions?', index: 318, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your career development?', index: 319, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your career path.', index: 320, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your aspirations?', index: 321, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your long-term goals.', index: 322, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your dreams?', index: 323, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your vision for the future?', index: 324, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your future plans.', index: 325, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your expectations?', index: 326, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your ability to manage expectations.', index: 327, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your desires?', index: 328, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your pursuit of happiness?', index: 329, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your happiness.', index: 330, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your passions?', index: 331, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your dedication to your passions.', index: 332, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your interests?', index: 333, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your hobbies?', index: 334, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your leisure time.', index: 335, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your creative projects?', index: 336, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your creative achievements.', index: 337, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your artistic pursuits?', index: 338, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your artistic endeavors?', index: 339, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your creative process.', index: 340, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your personal projects?', index: 341, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your personal achievements.', index: 342, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your personal goals?', index: 343, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal life?', index: 344, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your personal journey.', index: 345, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your goals?', index: 346, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your achievements in personal development.', index: 347, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your to-do list?', index: 348, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your daily habits?', index: 349, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your daily routine.', index: 350, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your responsibilities?', index: 351, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your ability to stay organized.', index: 352, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your commitments?', index: 353, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your personal goals?', index: 354, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your personal growth.', index: 355, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your emotions?', index: 356, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your emotional resilience.', index: 357, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your thoughts?', index: 358, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your mental health journey?', index: 359, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your mental state.', index: 360, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your workload?', index: 361, topic: 'positive thinking' },
  { text: 'Describe a time when you felt truly proud of your work ethic.', index: 362, topic: 'positive thinking' },
  { text: 'What is a positive way you handle feeling overwhelmed by your schedule?', index: 363, topic: 'positive thinking' },
  { text: 'What is something you are proud of achieving in your time management skills?', index: 364, topic: 'positive thinking' },
  { text: 'Write about a time you felt truly in control of your productivity.', index: 365, topic: 'positive thinking' }
]
